import React from "react";
// import './index.css';
import TemporaryHome from "./pages/temporary/TemporaryHome";

function App() {
  return (
    <div className="App">
        <TemporaryHome></TemporaryHome>
    </div>
  );
}

export default App;
